<template>
    <div class="document">
        <div class="page-header">
            <h1>{{ $t('Document Management') }} > {{ $t('Document') }} > {{ $t('Edit') }}</h1>
        </div>
        <div class="page-content">
            <div class="content-header">
                <h2>{{ $t('Edit Document') }}</h2>
            </div>
            <DocumentForm @action="editDocument" />
        </div>
    </div>
</template>
  
<script>
import { mapState, mapActions } from 'vuex';
import Common from '@/lib/common';
import Document from '@/lib/document';
import DocumentForm from '@/components/document/DocumentForm.vue';

export default {
    name: 'EditDocument',
    components: {
        DocumentForm
    },
    mounted(){
        this.loadDocument();
    },
    methods: {
        async loadDocument() {
            try {
                const loginInfo = Common.getLoginInfo();
                const documentId = this.$route.params.id;
                const document = await Document.loadDocument(this.apiUrl, documentId, loginInfo);
                this.setCurrentDocument(document);
            } catch (err) {
                console.log(err);
            }
        },
        async editDocument(form) {
            try {
                const loginInfo = Common.getLoginInfo();
                const documentId = this.$route.params.id;
                await Document.updateDocument(this.apiUrl, documentId, form, loginInfo);
                this.$router.push('/documents');
            } catch (err) {
                console.log(err);
            }
        },
        ...mapActions(['setCurrentDocument']),
    },
    computed: mapState({
        langcode: state => state.langcode,
        apiUrl: state => state.apiUrl,
    }),
}
</script>
  
<style scoped lang="scss">
.content-header {
    display: flex;

    h2 {
        flex: 1;
    }

    .action-button {
        margin-right: 20px;
    }
}
</style>
  